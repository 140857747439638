import { every, filter, includes, isEmpty, some, trimStart } from 'lodash'
import { type Ref } from 'vue'
import type { NavItemBase } from '@/types/nav'
import ENV from '@/constants/env'

const filterNavPermissions = <NavItem extends NavItemBase>(
    items: NavItem[],
    features: Ref<string[]>,
    roles: Ref<string[]>,
    isTithelyGivingEnabled?: boolean
): NavItem[] =>
    filter(items, (item: NavItem) => {
        // Invalid item
        if (!item.route && !item.action) {
            return false
        }

        const localBypass = ENV.environment === `local` || includes(features.value, 'dev-nav-override')

        // Tithely Giving check
        if (
            !localBypass &&
            item.isTithelyGivingEnabled !== undefined &&
            item.isTithelyGivingEnabled !== isTithelyGivingEnabled
        ) {
            return false
        }

        // Finally check the roles
        return (
            checkFeatures(features, localBypass ? item.localFeatures : item.features) &&
            checkRoles(roles, item.roles, item.orRoles)
        )
    })

const checkFeatures = (existingFeatures: Ref<string[]>, desiredFeatures: string[] = []): boolean => {
    if (isEmpty(desiredFeatures)) {
        return true
    }

    const negativeFeatures = filter(desiredFeatures, feature => feature[0] === `!`)
    if (some(negativeFeatures, feature => includes(existingFeatures.value, trimStart(feature, `!`)))) {
        return false
    }

    const positiveFeatures = filter(desiredFeatures, feature => feature[0] !== `!`)
    return every(positiveFeatures, feature => includes(existingFeatures.value, feature))
}

const checkRoles = (existingRoles: Ref<string[]>, desiredRoles: string[] = [], orRoles = false): boolean => {
    if (isEmpty(desiredRoles)) {
        return true
    }

    const negativeRoles = filter(desiredRoles, role => role[0] === `!`)
    if (some(negativeRoles, role => includes(existingRoles.value, trimStart(role, `!`)))) {
        return false
    }

    const positiveRoles = filter(desiredRoles, role => role[0] !== `!`)
    const roleCheck = orRoles ? some : every
    return roleCheck(positiveRoles ?? [], role => includes(existingRoles.value, role))
}

export { filterNavPermissions }
