<script setup lang="ts">
import { ref } from 'vue'
import { PhList, PhX } from '@phosphor-icons/vue'
import MainNavSettings from '@/layouts/components/MainNavSettings.vue'
import useNavMain from '@/composables/nav/main'
import useRouteHelper from '@/composables/routeHelper'

const { nav: menu } = useNavMain()
const { getCurrentRoute } = useRouteHelper(menu.value)

const open = ref(false)
</script>

<template>
    <header class="z-[1000] flex items-center justify-between gap-4 !p-4">
        <div class="!w-9 shrink-0 grow-0">
            <button
                type="button"
                class="flex size-9 items-center justify-center"
                @click="open = !open">
                <component
                    :is="open ? PhX : PhList"
                    weight="bold"
                    class="size-7" />
            </button>
        </div>

        <h1 class="truncate text-base font-semibold">{{ getCurrentRoute?.label || `` }}</h1>

        <div>
            <MainNavSettings />
        </div>
    </header>

    <nav
        v-if="open"
        class="top-18 absolute inset-x-0 z-[1001] h-[calc(100vh-68px)] bg-primary-50 ring-1 ring-primary-100">
        <ul class="!m-0 h-full overflow-y-auto !pb-8">
            <li
                v-for="item in menu"
                :key="item.label"
                class="border-b border-b-primary-100">
                <div class="flex items-center justify-between !pr-4">
                    <a
                        :href="item.route || `#`"
                        class="flex grow items-center justify-start !px-8 !py-3 text-lg/6 font-semibold">
                        {{ item.label }}
                    </a>
                </div>
            </li>
        </ul>
    </nav>
</template>
