<script lang="ts" setup>
import { computed, ref } from 'vue'
import { filter, find } from 'lodash'
import { cva } from 'class-variance-authority'
import { useMe } from '@/queries/me'
import BIcon from '@/components/BIcon.vue'
import type { SideNavItem } from '@/types/sideNav'
import type { Nullable } from '@/types/utility'

const props = defineProps<{ nav: SideNavItem[] }>()

const { hasTrinity: trinity } = useMe()

const expanded = ref(false)

const current = (itemRoute: string) => itemRoute === window.location.pathname

const getCurrent = computed((): Nullable<SideNavItem> => find(props.nav, item => current(item.route)) || null)
const mobileNav = computed((): SideNavItem[] =>
    getCurrent.value
        ? (filter(props.nav, (item: SideNavItem) => !current(item.route)) as SideNavItem[]) || []
        : (props.nav as SideNavItem[])
)

const navClasses = cva(`z-50 flex flex-col print:hidden lg:hidden`, {
    variants: {
        trinity: {
            true: `bg-primary-50`,
            false: `bg-secondary-50`
        }
    }
})

const iconWrapperClasses = cva(`relative flex shrink-0 items-center justify-center`, {
    variants: {
        trinity: {
            true: `w-14 `
        }
    }
})

const iconClasses = cva(``, {
    variants: {
        trinity: {
            true: `size-5`,
            false: `size-6`
        }
    }
})

const itemClasses = cva(`flex h-[50px] flex-row items-center`, {
    variants: {
        link: {
            true: `opacity-40 transition-opacity`,
            false: `!pr-4`
        },
        trinity: {
            true: `text-sm font-semibold text-primary-950`,
            false: `gap-4 !px-[22px] text-base/none font-bold text-primary`
        }
    },
    compoundVariants: [
        {
            link: true,
            trinity: false,
            class: `opacity-40`
        },
        {
            link: true,
            trinity: true,
            class: `opacity-75`
        },
        {
            link: false,
            trinity: true,
            class: `bg-primary/20`
        }
    ],
    defaultVariants: {
        link: false
    }
})

const expandedIconClasses = cva(`!ml-auto h-6 w-6 origin-center transition-transform`, {
    variants: {
        expanded: {
            true: `rotate-180`
        }
    }
})

const listClasses = cva(`absolute top-0 w-full shadow-lg`, {
    variants: {
        trinity: {
            true: `bg-primary-50`,
            false: `bg-secondary-50`
        }
    }
})
</script>

<template>
    <nav :class="navClasses({ trinity })">
        <button
            :class="itemClasses({ trinity })"
            @click="expanded = !expanded">
            <span :class="iconWrapperClasses({ trinity })">
                <BIcon
                    v-if="getCurrent"
                    :icon="getCurrent.icon"
                    :class="iconClasses({ trinity })" />
            </span>

            {{ getCurrent ? getCurrent.label : `Menu` }}

            <BIcon
                icon="chevron-down"
                :class="expandedIconClasses({ expanded })" />
        </button>

        <Transition
            enter-active-class="transition-all"
            leave-active-class="transition-all"
            enter-from-class="-translate-y-4 opacity-0"
            leave-to-class="-translate-y-4 opacity-0">
            <div
                v-if="expanded"
                class="relative w-full">
                <ul :class="listClasses({ trinity })">
                    <li
                        v-for="item in mobileNav"
                        :key="item.route">
                        <a
                            :href="item.route"
                            :class="itemClasses({ link: true, trinity })">
                            <span :class="iconWrapperClasses({ trinity })">
                                <BIcon
                                    :icon="item.icon"
                                    :class="iconClasses({ trinity })" />
                            </span>
                            {{ item.label }}
                        </a>
                    </li>
                </ul>
            </div>
        </Transition>
    </nav>
</template>
