<script lang="ts" setup>
import { cva } from 'class-variance-authority'
import { useMe } from '@/queries/me'
import BIcon from '@/components/BIcon.vue'
import type { SideNavItem } from '@/types/sideNav'

defineProps<{ nav: SideNavItem[] }>()

const { hasTrinity: trinity } = useMe()

const current = (itemRoute: string) => itemRoute === window.location.pathname

const navClasses = cva(`relative hidden shrink-0 grow-0 lg:block lg:print:hidden`, {
    variants: {
        trinity: {
            true: `bg-primary-50 !px-0 !py-4`,
            false: `bg-secondary-50 !pt-[30px]`
        }
    }
})

const listClasses = cva(`relative`, {
    variants: {
        current: {
            true: ``
        },
        trinity: {
            true: ``
        }
    },
    compoundVariants: [
        {
            current: true,
            trinity: false,
            class: `before:absolute before:left-0 before:top-0 before:h-full before:w-1.5 before:rounded-r-[20px] before:bg-primary`
        }
    ]
})

const linkClasses = cva(`flex items-center transition-opacity`, {
    variants: {
        current: {
            true: `pointer-events-none opacity-100`,
            false: `hover:opacity-100`
        },
        trinity: {
            true: `min-h-9 text-sm font-semibold text-primary-950 hover:bg-primary/10`,
            false: `h-[38px] gap-4 !px-[22px] font-bold text-primary`
        }
    },
    compoundVariants: [
        {
            current: false,
            trinity: false,
            class: `opacity-40 `
        },
        {
            current: false,
            trinity: true,
            class: `opacity-75`
        },
        {
            current: true,
            trinity: true,
            class: `bg-primary/20`
        }
    ]
})

const iconWrapperClasses = cva(`relative flex shrink-0 items-center justify-center`, {
    variants: {
        trinity: {
            true: `w-14 `
        }
    }
})

const iconClasses = cva([], {
    variants: {
        trinity: {
            true: `size-5`,
            false: `size-6`
        }
    }
})
</script>

<template>
    <nav :class="navClasses({ trinity })">
        <ul class="flex flex-col gap-4">
            <li
                v-for="item in nav"
                :key="item.route"
                :class="listClasses({ current: current(item.route), trinity })">
                <a
                    :href="item.route"
                    :class="linkClasses({ current: current(item.route), trinity })">
                    <span :class="iconWrapperClasses({ trinity })">
                        <BIcon
                            :icon="item.icon"
                            :class="iconClasses({ trinity })" />
                    </span>

                    {{ item.label }}
                </a>
            </li>
        </ul>
    </nav>
</template>
