<script setup lang="ts">
import { computed, ref } from 'vue'
import { some } from 'lodash'
import { cva } from 'class-variance-authority'
import { PhCaretDown } from '@phosphor-icons/vue'
import useNavMain from '@/composables/nav/main'
import useRouteHelper from '@/composables/routeHelper'

const { nav: menu } = useNavMain()
const { isCurrentRoute } = useRouteHelper(menu.value)

const open = ref(false)

const primary = computed(() => menu.value.slice(0, 5))
const secondary = computed(() => menu.value.slice(5, menu.value.length))
const secondaryCurrent = computed(() => some(secondary.value, nav => isCurrentRoute(nav?.route)))

const primaryClasses = cva(
    `relative inline-block !h-9 rounded-full !px-4 text-base/9 font-semibold transition-colors`,
    {
        variants: {
            current: {
                true: `bg-primary text-white`,
                false: `text-theme-foreground hover:bg-primary/25`
            }
        }
    }
)
const secondaryClasses = cva(`block !px-4 !py-2 text-base font-medium transition-colors`, {
    variants: {
        current: {
            true: `bg-primary text-white`,
            false: `text-theme-foreground hover:bg-primary/25`
        }
    }
})
const toggleSecondaryClasses = cva(
    `inline-flex !h-9 items-center gap-2 rounded-full !px-4 text-base/9 font-semibold transition-colors`,
    {
        variants: {
            open: {
                true: `bg-primary text-white`,
                false: `hover:bg-primary/25`
            }
        }
    }
)
</script>

<template>
    <nav>
        <ul class="flex flex-row items-center gap-4">
            <li
                v-for="item in primary"
                :key="item.label">
                <a
                    :href="item.route"
                    :class="primaryClasses({ current: isCurrentRoute(item.route) })">
                    {{ item.label }}
                </a>
            </li>
            <li
                v-if="secondary.length"
                class="relative">
                <button
                    ref="secondaryButton"
                    type="button"
                    :class="toggleSecondaryClasses({ open: open || secondaryCurrent })"
                    @click="open = !open">
                    More
                    <PhCaretDown class="size-5" />
                </button>

                <ul
                    v-show="open"
                    class="absolute top-full z-[1000] mt-1 w-48 overflow-hidden rounded-sm bg-white shadow-md">
                    <li
                        v-for="child in secondary"
                        :key="child.label">
                        <a
                            :href="child.route"
                            :class="secondaryClasses({ current: isCurrentRoute(child.route) })">
                            {{ child.label }}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>
