import { computed } from 'vue'
import { find, includes } from 'lodash'
import type { NavMainItem } from '@/types/nav/main'

const useRouteHelper = (menu: NavMainItem[] = []) => {
    const getMyProfileRoute = computed(() => find(menu, ({ label }) => label === `My Profile`)?.route)
    const normalizeRoute = (route: string): string => route.replace(`/r/`, `/`).split('/')[1]

    const getCurrentRoute = computed(() => find(menu, ({ route }) => isCurrentRoute(route)))

    const isCurrentRoute = (route?: string): boolean => {
        if (!route) {
            return false
        }

        // Edge case where the current route is the people view page for the current user
        if (includes(route, `people/view`)) {
            return route === window.location.pathname
        }

        if (route === `/people` && normalizeRoute(route) === normalizeRoute(window.location.pathname)) {
            return getMyProfileRoute.value !== window.location.pathname
        }

        return route === window.location.pathname || normalizeRoute(route) === normalizeRoute(window.location.pathname)
    }

    return {
        getMyProfileRoute,
        normalizeRoute,
        getCurrentRoute,
        isCurrentRoute
    }
}

export default useRouteHelper
