<script lang="ts" setup>
import { defineAsyncComponent, watch } from 'vue'
import { includes, some, startsWith } from 'lodash'
import { useRoute } from 'vue-router'
import { useMe } from '@/queries/me'
import { useTithely } from '@/queries/tithely'
import useEcho from '@/composables/echo'
import { PUBLIC_ROUTES } from '@/constants/routes'
import SupportToolbar from '@/layouts/components/SupportToolbar.vue'
import MainNav from '@/layouts/components/MainNav.vue'
import SideNav from '@/layouts/components/SideNav.vue'
import type { SideNavLocation } from '@/types/sideNav'
import { cva } from 'class-variance-authority'

interface Props {
    feature?: string
    hasFeature?: boolean
    isVue?: boolean
    sideNav?: SideNavLocation
}

defineProps<Props>()

useEcho()
const { status, data, isFetching, hasTrinity: trinity } = useMe()
const route = useRoute()
const { billing, syncTithelyBilling } = useTithely()

// Status/Permissions checks
watch(
    data,
    newData => {
        if (
            !isFetching.value && // Wait for any refreshes to complete
            status.value === `success` &&
            newData &&
            includes(newData.features, `vue-login`) &&
            !some(PUBLIC_ROUTES, path => startsWith(route.path, path))
        ) {
            switch (true) {
                case newData.organization.status === 2 && route.path !== `/r/suspended`:
                    window.open(`/r/suspended`, `_self`)
                    break
                case newData.organization.status === 4 && route.path !== `/disabled`:
                    window.open(`/disabled`, `_self`)
                    break
                case includes(newData.roles ?? [], `checkin`):
                    window.open(`/checkin/v3`, `_self`)
                    break
                case newData.organization.status < 2 && includes([`/r/suspended`, `disabled`], route.path):
                    window.open(`/r/dashboard`, `_self`)
                    break
            }
        }

        if (trinity) {
            document.documentElement.classList.toggle(`trinity-ui`, trinity.value)
        }
    },
    {
        immediate: true
    }
)

watch(
    [data, billing],
    ([newData, newBilling]) => {
        //Check for all access customer and service plan integration
        if (newData && newBilling?.allAccessCustomer && !includes(newData.features, `service_plan_integration`)) {
            //If not enabled sync billing and enable the extension through the breeze backend
            syncTithelyBilling()
        }
    },
    {
        immediate: true
    }
)

let showInviteUserModal = false

const InviteUserModal = defineAsyncComponent(() => import('../components/modals/InviteUserModal.vue'))

const mainClasses = cva(`grid h-[calc(100vh-68px)] grid-cols-1`, {
    variants: {
        trinity: {
            true: `lg:h-[calc(100vh-80px)]`,
            false: `lg:h-[calc(100vh-76px)]`
        },
        sideNav: {
            true: ``
        }
    },
    compoundVariants: [
        {
            trinity: true,
            sideNav: true,
            class: `lg:grid-cols-[16rem_1fr]`
        },
        {
            trinity: false,
            sideNav: true,
            class: `lg:grid-cols-[260px_1fr]`
        }
    ]
})

// Show the invite user modal if the user has not completed the invite users onboarding step
watch(
    data,
    newData => {
        if (newData && includes(newData.organization.onboardingSteps, 'invite_users')) {
            showInviteUserModal = true
        }
    },
    {
        immediate: true
    }
)
</script>

<template>
    <SupportToolbar />

    <template v-if="status === `success`">
        <MainNav />

        <div v-if="feature && !hasFeature" />

        <div
            v-else-if="isVue"
            :class="mainClasses({ trinity, sideNav: Boolean(sideNav) })">
            <SideNav
                v-if="sideNav"
                :menu="sideNav" />

            <div
                v-if="trinity"
                class="overflow-hidden rounded-tl-lg border-l border-t bg-white">
                <slot />
            </div>
            <slot v-else />
        </div>
        <slot v-else />
    </template>

    <InviteUserModal
        v-if="showInviteUserModal"
        ref="inviteUserModal"
        :me="data" />
</template>

<style scoped>
:slotted(div) {
    @apply z-[1] grow overflow-y-auto print:overflow-y-visible;
}
</style>
