import { watch, computed } from 'vue'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { includes } from 'lodash'
import { plainToInstance } from 'class-transformer'
import queryClient from '@/plugins/query'
import useApi from '@/composables/api'
import useEventBus from '@/composables/eventBus'
import { QUERY_KEYS } from '@/constants/query'
import { TIME } from '@/constants/time'
import type { CurrentUserUpdateRequest } from '@/services/api/client'
import MeResource from '@/services/api/transformers/MeResource'

const useMe = () => {
    const { api } = useApi()
    const { on, emit } = useEventBus()

    const query = useQuery({
        queryKey: QUERY_KEYS.me,
        queryFn: () => api.authorizationLoggedInUser.getMe(),
        select: ({ data }) => plainToInstance(MeResource, data),
        staleTime: TIME.Minute()
    })

    const hasFeature = (key: string) => includes(query.data.value?.features ?? [], key)
    const hasRole = (key: string) => includes(query.data.value?.roles ?? [], key)
    const isLinked = computed(() => !!query.data.value?.organization.tithelyOrganizationId)
    const hasGiving = computed(() => !!query.data.value?.organization.tithelyGiving)
    const hasBreezeBundle = computed(() => includes(query.data.value?.features, `breeze-bundle`))
    const hasTrinity = computed(
        () =>
            hasBreezeBundle.value ||
            includes(query.data.value?.features, `trinity-ui`) || // To be removed in favour of hub:trinity-ui
            includes(query.data.value?.features, `hub:trinity-ui`)
    )

    // Emit/Handle events so React user data is synced with Vue user data
    const emitUpdate = () => (query.data.value ? emit(`me:updated`, query.data.value) : null)
    on(`me:booted`, () => emitUpdate())
    on(`me:refresh`, () => queryClient.invalidateQueries({ queryKey: QUERY_KEYS.me }))
    on(`me:clear`, () => queryClient.removeQueries({ queryKey: QUERY_KEYS.me }))

    watch(query.data, () => emitUpdate(), { deep: true, immediate: true })

    return {
        ...query,
        hasFeature,
        hasRole,
        isLinked,
        hasGiving,
        hasBreezeBundle,
        hasTrinity
    }
}

const useMeUpdate = () => {
    const { api } = useApi()

    return useMutation({
        mutationFn: (requestBody: CurrentUserUpdateRequest) => api.authorizationLoggedInUser.updateMe({ requestBody }),
        onSuccess: data => queryClient.setQueryData(QUERY_KEYS.me, data)
    })
}

export { useMe, useMeUpdate }
