import account from '@/lang/en/account'
import general from '@/lang/en/general'
import groups from '@/lang/en/groups'
import login from '@/lang/en/login'
import people from '@/lang/en/people'
import servicePlan from '@/lang/en/servicePlan'
import user from '@/lang/en/user'
import errors from '@/lang/en/errors'
import permissions from '@/lang/en/permissions'
import extensions from '@/lang/en/extensions'

export default {
    account,
    errors,
    extensions,
    general,
    groups,
    login,
    people,
    servicePlan,
    user,
    permissions
}
